/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useColorScheme } from "@mui/material/styles";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// COMPONENTS ///
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CreateFeedback from "components/feedback/dialog/create";
import AnimateLogo from "./logo";
import UserThumbnail from "./user/thumbnail";
import AccountSwitch from "./account/switch";
import ConfirmNetworkFee from "./dialog/confirm_net_fee";
/// STYLES ///
import { toolBarStyle } from "styles/style";
/// COLORS ///
import { light_grey_color } from "styles/style";
/// URLS ///
import { home_url } from "./endpoints";
import { login_url } from "./endpoints";
/// IMAGES ///
import LogoIcon from "static/logo_icon.png";
import MarketLogoIcon from "static/market_icon.png";

export default function NavBar() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shop_view, user } = useAppSelector(state => state.authentication);
  const { mode, setMode } = useColorScheme();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (user?.region_data?.uses_network_fee && !user?.accepted_network_fee && !user?.declined_network_fee) {
      setOpen(true);
    }
  }, [user]);

  useEffect(() => {
    if (shop_view) {
      setMode("light");
    }
    else {
      setMode("dark");
    }
  }, [shop_view, setMode]);

  useEffect(() => {
    if (user.token) {
      console.log("Connecting to Server");
      dispatch({ type: "socket/connect" });
    }

    return () => {
      console.log("Disconnecting from Server");
      dispatch({ type: "socket/disconnect" });
    }
  }, [user.token, dispatch]);

  return (
    <AppBar position="fixed" sx={{ paddingLeft: 0, marginLeft: 0, borderBottom: `1px solid ${light_grey_color}`, height: "50px" }} elevation={0}>
      <Toolbar sx={toolBarStyle} disableGutters={true} variant="dense">
        <img
          onClick={() => navigate(home_url)}
          src={shop_view ? LogoIcon : MarketLogoIcon}
          width="25"
          height="25"
          style={{ objectFit: "contain" }}
          alt="Arris" />
        {
          !user?.pending_account_confirmation ?
            <AccountSwitch /> :
            null
        }
        <Box sx={{ flexGrow: 1 }}>
        </Box>
        {
          user.id ?
            <CreateFeedback /> :
            null
        }
        {
          user.id ?
            <UserThumbnail /> :
            <Button size="small" onClick={() => navigate(login_url, { replace: false })}>
              Sign In
            </Button>
        }
        <ConfirmNetworkFee open={open} setOpen={setOpen} />
      </Toolbar>
    </AppBar>
  );
}
